import './changePinModal.scss';
import { useState } from 'react';
import { text as t, capText as c } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

const ChangePinModal = ({id, callback}) => {
    const { removeModal } = useModal();
    const { callAPI, updateHeaderMessage } = useUtils();
    const [ disabled, setDisabled ] = useState();

    const onSubmit = (event, setLoading) => {
        setLoading(true);
        callAPI('adminPinChange', adminPinChangeCallback, { webPortalUserId: id}, '', setLoading )
    }

    const adminPinChangeCallback = (data, setLoading) => {
        setDisabled(false);
        setLoading(false);
        updateHeaderMessage(data);
        if (data.isSuccessful) {
            callback()
        }
    }

    const onCancel = () => {
        removeModal();
    }

    return (
        <Modal title={c.warning} classes={'change-pin-modal'}>
            <div className='grid'>
                <div className='full change-pin-modal-warning'>{t.changeAdminPinWarning}</div>
                <Button classes='red half change-pin-modal-confirm' disabled={disabled} type='submit' onClick={(event, setLoading) => {onSubmit(event, setLoading)}}>{t.confirm}</Button>
                <Button classes='blue half change-pin-modal-cancel' disabled={disabled} onClick={onCancel}>{t.cancel}</Button>
            </div>
        </Modal>
    )
}

export default ChangePinModal;