import './locationSettings.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { deepCopy } from 'components/shared/componentUtils';
import { locationTabsList as tabs } from 'layouts/Locations/LocationTabs';
import { text as t } from 'shared/text';
import useAuth from 'hooks/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Checkbox from 'components/Checkbox/Checkbox';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';

const LocationSettings = () => {
    const { id }  = useParams();
    const { getCountry } = useAuth();
    const { inputValues, changeInputValues, 
            changeInputErrors, clearAllInputs,
            updateNestedInputData, reNestedData } = useInputs();
    const { updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { callAPI, callAPIGet,
            hasRank, updateHeaderMessage } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ alerts, setAlerts ] = useState();
    const [ alertsContacts, setAlertsContacts ] = useState();
    const [ firstIndex, setFirstIndex ] = useState(0); 
    const [ secondIndex, setSecondIndex ] = useState(0);
    const [ masked, setMasked ] = useState([true]);
    const permitted = hasRank('owner', true);
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const deviceTypes = useRef();
    const country = useRef(getCountry());

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        runOnce.current = true;
        callAPI('locationDetails', locationDetailsCallback, {id: id});
        callAPIGet('locationAlertsGet', locationAlertsGetCallback, {locationId: id})
        callAPIGet('locationAlertsContacts', locationAlertsContactsCallback, {locationId: id} )
        return () => {clearAllInputs()};
    }, []);

    useEffect(()=> {
        if (alerts && alertsContacts && !setOnce.current) {
            const newAlerts = deepCopy(alerts);
            const firstContact = alertsContacts[alerts.firstContact.contactId];
            const secondContact = alertsContacts[alerts.secondContact.contactId];
            const firstId = firstContact?.id;
            const secondId = secondContact?.id;
            if (firstId) {
                newAlerts.firstContact.phone = firstContact.phone;
                newAlerts.firstContact.email = firstContact.email;
                newAlerts.firstContact.name = firstContact.name;
                newAlerts.firstContact.id = firstId;
            }
            if (secondId) {
                newAlerts.secondContact.phone = secondContact.phone;
                newAlerts.secondContact.email = secondContact.email;
                newAlerts.secondContact.name = secondContact.name;
                newAlerts.secondContact.id = secondContact.id;
            }
            setOnce.current = true;
            const dropdownList = {
                ['firstContact-id']: {list: 0,  labelProp: 'label', appendProp: 'name'},
                ['secondContact-id']: {list: 0, labelProp: 'label', appendProp: 'name'}
            }
            const update = updateNestedInputData(newAlerts, dropdownList, [alertsContacts]);
            update && setMasked([false]);
        }
    }, [alerts, alertsContacts])

    useEffect(() => {
        changeInputErrors(null);
    }, [inputValues])

    useEffect(() => {
        if (!setOnce.current) {return}
        const newValues = deepCopy(inputValues);
        const firstContact = alertsContacts[firstIndex]; 
        newValues['firstContact-phone'] = firstContact.phone;
        newValues['firstContact-email'] = firstContact.email;
        newValues['firstContact-name'] = firstContact.name;
        newValues['firstContact-contactId'] = firstIndex;
        newValues['firstContact-id'] = firstContact.id;
        changeInputValues(newValues);
    }, [firstIndex])

    useEffect(() => {
        if (!setOnce.current) {return}
        const newValues = deepCopy(inputValues);
        const secondContact = alertsContacts[secondIndex];
        newValues['secondContact-phone'] = secondContact.phone;
        newValues['secondContact-email'] = secondContact.email;
        newValues['secondContact-name'] = secondContact.name;
        newValues['secondContact-contactId'] = secondIndex;
        newValues['secondContact-id'] = secondContact.id;
        changeInputValues(newValues);
    }, [secondIndex])

    useEffect(()=>{
        if (!setOnce.current) {return};
        let newValues;
        if (!inputValues?.['firstContact-id'] && (inputValues['firstContact-textAlert'] || inputValues['firstContact-emailAlert'])) {
            newValues = deepCopy(inputValues);
            newValues['firstContact-textAlert'] = false;
            newValues['firstContact-emailAlert'] = false;
        }
        if (!inputValues?.['secondContact-id'] && (inputValues['secondContact-textAlert'] || inputValues['secondContact-emailAlert'])) {
            newValues = newValues ? newValues : deepCopy(inputValues)
            newValues['secondContact-textAlert'] = false;
            newValues['secondContact-emailAlert'] = false;
        }
        newValues && changeInputValues(newValues);
    }, [inputValues]);

    const locationDetailsCallback = (data) => {
        if (data?.isSuccessful) {
            const details = data?.locationDetails;
            const types = data?.kioskTypes;
            deviceTypes.current = types;
            setPageData(details);
            updateLocationHeader(details);
            updateLocationBreadcrumbs(details);
        } else {
            setPageData({})
        }
    }

    const locationAlertsGetCallback = (data) => {
        if (data.isSuccessful) {
            delete data.errorMessage;
            delete data.httpStatusCode;
            delete data.isSuccessful;
            delete data.responseTypeEnum;
            setAlerts(data);
        }
    }

    const locationAlertsContactsCallback = (data) => {
        setAlertsContacts(data?.kioskAlertContacts ? data.kioskAlertContacts : []);
    }

    const onDropdownCallback = (data) => {
        if (data.section === 'firstContact') {
            setFirstIndex(data.index);
        } else {
            setSecondIndex(data.index);
        }
    }

    const onSubmit = (event, setLoading) => {
        const props = {event: event, setLoading: setLoading}
        setLoading(true);
        changeInputErrors({})
        const params = reNestedData(alerts);
        params.firstContact.contactId = parseInt(params.firstContact.contactId);
        params.secondContact.contactId = parseInt(params.secondContact.contactId);
        delete params.firstContact.phone;
        delete params.firstContact.email;
        delete params.secondContact.phone;
        delete params.secondContact.email;
        params.locationId = parseInt(id);
        callAPI('locationAlertsSet', locationServicesSetCallback, params, '', props);
    }

    const locationServicesSetCallback = (data, props) => {
        props.setLoading(false);
        updateHeaderMessage(data, props);
    }

    return (
        <Page
            subPage={t.settings}
            tabs={tabs}
            contentClasses='grid location-details'
        >
            {<Card label={t.grcAlerts} classes='full'>
                <Checkbox label={t.active} section='kioskAlerts' line='active' classes='third' noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTenUGD : t.alertForTen} section='kioskAlerts' line='lowRecycler10' classes='third'
                    type='max100' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <Input label={country.current === 'UG' ? t.alertForTwentyUGD : t.alertForTwenty} section='kioskAlerts' line='lowRecycler20' classes='third'
                    type='max100' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <Dropdown label={t.primaryAlert} section='firstContact' line='id' classes='third' data={alertsContacts} onClick={onDropdownCallback}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='firstContact' line='textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['firstContact-id']}/>
                <Input label={t.phoneNumber} section='firstContact' line='phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='firstContact' line='emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['firstContact-id']}/>
                <Input label={t.email} section='firstContact' line='email' classes='quarter display-only' placeholder={t.none}
                    disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                
                <Dropdown label={t.secondaryAlert} section='secondContact' line='id' classes='third' data={alertsContacts} onClick={onDropdownCallback}
                    labelProp='label' appendProp='name' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]}noPermission={!permitted}/>
                <Checkbox label={t.alertText} section='secondContact' line='textAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['secondContact-id']}/>
                <Input label={t.phoneNumber} section='secondContact' line='phone' classes='quarter display-only' placeholder={t.none}
                    type='phone' disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                <Checkbox label={t.alertEmail} section='secondContact' line='emailAlert' classes={`twelfth`} noPermission={!permitted}
                    disabled={!inputValues?.['kioskAlerts-active'] || !inputValues?.['secondContact-id']}/>
                <Input label={t.email} section='secondContact' line='email' classes='quarter display-only' placeholder={t.none}
                    disabled={!inputValues?.['kioskAlerts-active']} masked={masked?.[0]} noPermission={!permitted}/>
                {permitted && <Button
                    classes={`green last sixth`}
                    type='submit'
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                    >{t.submit}
                </Button>}
            </Card>}
        </Page>
    )
}

export default LocationSettings;
