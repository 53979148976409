import './tablePageSelector.scss';
import { useEffect, useState } from 'react';
import { ifElse } from 'components/shared/componentUtils';
import { tablesData as t } from 'components/Tables/tablesData';
import useTable from '../../TableHooks/useTables';

const TablePageSelector = ({id, layout}) => {
    const { tablePageCounts, tableCurrentPages, updateTable } = useTable();
    const [ disableLeft, setDisableLeft ] = useState(true);
    const [ disableRight, setDisableRight ] = useState(true);
    const navId = id + '-table-nav';
    const arrowNav = layout?.navStyle === 'arrow';
    const pageCount = ifElse(tablePageCounts?.[id]);
    const currentPage = ifElse(tableCurrentPages?.[id]?.[0], 1);
    
    useEffect(()=>{
        updateNavState();
    }, [pageCount, currentPage])

    const updateNavState = (page) => {
        const oldPage = document.getElementById(navId).getElementsByClassName('current')?.[0];
        if (oldPage) {oldPage.classList.remove('current')};
        document.getElementById(id + '-page-selector-num-' + currentPage)?.classList.add('current');
        setDisableLeft(currentPage === 1 ? true : false);
        setDisableRight(currentPage === pageCount ? true : false);
    };

    const createPageButtons = () => {
        if (!pageCount) {return t.nullVal}
        return pageCount > 5 ? createShortenedPages() : createAllPages(pageCount);
    };

    const createAllPages = (count) => {
        if (count) {
            const array = [];
            for (let i = 1; i <= count; i++) {
                array.push(i);
            }
            return (array.map((i) => {
                return createNavButton(i);
            }));
        }
    };

    const createShortenedPages = () => {
        const array = [];
        currentPage - 1 >= 2 && array.push(createNavButton(1));
        currentPage - 1 >= 3 && array.push(<div className='table-page-selector-concat' key='concat1'>...</div>);
        currentPage - 1 >= 1 && array.push(createNavButton(currentPage - 1));
        array.push(createNavButton(currentPage));
        currentPage + 1 <= pageCount && array.push(createNavButton(currentPage + 1));
        currentPage + 3 <= pageCount && array.push(<div className='table-page-selector-concat' key='concat2'>...</div>);
        currentPage + 2 <= pageCount && array.push(createNavButton(pageCount));
        return array;
    };

    const createNavButton = (val) => {
        return (<div
            key={val}
            id={id + '-page-selector-num-' + val}
            className={`table-page-selector-number table-page-selector-button ${val === currentPage ? 'current' : ''}`}
            onClick={() => {moveToPage(val)}}
        >{val}</div>);
    };

    const moveToPage = (place) => {
        updateTable('currentPages', id, [place]);
    };

    const movePage = (num) => {
        if (num > 0 && currentPage === pageCount) {return};
        if (num < 0 && currentPage === 1) {return};
        const newPage = currentPage + num;
        updateTable('currentPages', id, [newPage]);
    };

    return (
        <div id={navId} className={`table-page-selector-container ${layout?.hideNav ? 'hide' : ''}`}>
            <div className={`table-page-selector-button first end ${disableLeft ? 'disabled' : ''}`} 
                onClick={() => {moveToPage(1)}}
            >{t.first}</div>
            <div className={`table-page-selector-button direction ${disableLeft ? 'disabled' : ''} ${!arrowNav ? 'prev' : ''}`} 
                onClick={() => {movePage(-1)}}
            >{arrowNav? '<': t.previous}</div>
            <div className='table-page-selector-numbers'>{createPageButtons()}</div>
            <div className={`table-page-selector-button direction ${disableRight ? 'disabled' : ''} ${!arrowNav ? 'next' : ''}`} 
                onClick={() => {movePage(1)}}
            >{arrowNav ? '>' : t.next}</div>
            <div className={`table-page-selector-button last end ${disableRight ? 'disabled' : ''}`} 
                onClick={() => {moveToPage(pageCount)}}
            >{t.last}</div>
        </div>
    );
};

export default TablePageSelector;
