import './page.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Tab from 'app-components/Tab/Tab';

const Page = ({ page, subPage, tabs, classes, contentClasses, children }) => {
    const { id } = useParams();
    const { navigateTo } = useComponents();
    const { clearAllInputs } = useInputs();
    const { size, setHeader, 
            pageMenuOpen, openPageMenu } = useProfile();
    const { hasRank } = useUtils();

    const runOnce = useRef(false);

    useEffect(()=>{
        if (runOnce.current) {return}
        runOnce.current = true;
        page && setHeader(page);
        clearAllInputs();
    },[])

    useEffect(()=>{
        window.scrollTo(0,0)
    }, [window.location.href])
    
    const buildTabsMenu = () => {   
        return tabs.map(( tab, i ) => {
            if (!hasRank(tab?.rank)) {return}
            const path = tab.path.replace(':id', id);
            return <div key={i} 
                className={`page-menu-item ${location.pathname === path ? 'selected' : ''}`} 
                onClick={()=>{onItemClick(path)}}
                >{tab.label}    
            </div>
        })
    }

    const onPageMenuClick = () => {
        openPageMenu(!pageMenuOpen)
    }

    const onItemClick = (path) => {
        navigateTo(path);
        openPageMenu(false);
    }

    return (
        <div className={`page ${classes? classes : ''} ${subPage && size !== 'phone' ? 'has-tabs' : ''}`}>
            {(subPage && size !=='phone') && <div className='page-header'>
                <div className='tab-bar'>
                    {tabs && tabs.map((props, i) => {
                        if (!hasRank(props?.rank)) {return}
                        return <Tab
                            key={i}
                            data={props}
                            selected={props.label === subPage? true : false}
                        />
                    })}
                </div>
            </div>}
            <div className={`page-content ${subPage ? 'has-header' : ''} ${contentClasses ? contentClasses : ''}`}>
                {children}
            </div>
            {(size === 'phone' && tabs) && <div className='page-menu-container'>
                <Button icon='list' classes='page-menu-button' onClick={()=>{onPageMenuClick()}}/>
                {<div id='page-menu' className={`page-menu ${pageMenuOpen ? 'open' : ''}`}>
                    {buildTabsMenu()}
                </div>}
            </div>}
        </div>
    )
}

export default Page;
