import './gamePerformanceReports.scss';
import { useEffect, useState } from 'react';
import { gamePerformanceReportsLayout as layout } from 'layouts/Reports/GamePerformanceReportsLayout';
import { deepCopy, getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import * as e from 'shared/excel';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';
const tableId = 'device-game-performance';
const typeId = tableId + '-typeId';

const GamePerformanceReports = () => {
    const { updateDropdown } = useInputs();
    const { tableData, tableTotals, tableDropdowns,
            getTableColumns, getURLParams,
            updateTable, updateDropdowns } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ tableRecords, setTableRecords ] = useState();
    const [ deviceTypes, setDeviceTypes ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ masked, setMasked ] = useState([true]);

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPI('deviceTypes', deviceTypesCallback);
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[typeId];
            sorts && updateDropdown(typeId, sorts.value, sorts.label);
        }
    }, [deviceTypes]);

    const getReportData = (range) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        callAPIGet('report-GamePerformance', gamePerformanceCallback, params);
    }

    const gamePerformanceCallback = (data) => {
        setTableRecords(data?.data ? data.data : []);
        setMasked([false]);
    }

    const deviceTypesCallback = (data, skip) => {
        let list = data?.kioskTypes ? data.kioskTypes : [];
        setDeviceTypes(list);   
    }

    const onDeviceTypeSelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'terminalTypeDescription'
        }
        updateDropdowns(tableId, typeId, data);
    }

    const onDateRangePick = (range) => {
        setMasked([true]);
        setCurrentRange(range);
        getReportData(range);
    }

    const onExportToExcel = () => { 
        const dateInfo = {
            type: 'table',
            columns: ['startDate', 'endDate'],
            headers: {
                selector: t.deviceType,
                startDate: t.startDate,
                endDate: t.endDate,
            },
            data:[{
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1]),
            }],
            configs: {
                formats: {selector: 'text-wrap'}
            }
        };
        if (tableDropdowns?.[tableId]?.[typeId]) {
            dateInfo.data[0].selector = tableDropdowns[tableId][typeId].label,
            dateInfo.columns.splice(0,0, 'selector');
        }

        const tableRowData = deepCopy(tableData[tableId]);
        if (layout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, layout);
        const tableInfo = e.getExcelTable(t.gamePerformance, columns, tableRowData, layout);
        const sizes = columns.map(key => layout.sizes[key]);
        e.exportToFile('Gameroom Performance Report', [dateInfo, tableInfo], sizes);
    }

    return (
        <TableListed
            id={tableId}
            data={tableRecords}
            layout={layout}
            masked={masked?.[0]}
            searchBarContent={<>
                <Dropdown
                    id={typeId}
                    classes='quarter'
                    label={t.terminalType}
                    data={deviceTypes}
                    masked={masked?.[0]}
                    nullable={t.all}
                    hideErrors={true}
                    callback={onDeviceTypeSelect}
                />
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    disabled={masked?.[0]}
                    callback={onDateRangePick}
                />
                <Button 
                    classes='green sixth match-labeled-input'
                    disabled={masked?.[0]}
                    onClick={onExportToExcel}
                >{t.exportToExcel}</Button>
            </>}
        />
    )
}

export default GamePerformanceReports;
