import './table.scss';
import { useEffect, useRef, useState } from 'react';
import { ifElse, deepCopy, isValid } from 'components/shared/componentUtils';
import { getSubtitleContent, getSubtotalContent, formatContent } from './tableUtils';
import { sortList } from 'app-components/Table/tableUtils';
import useAuth from 'hooks/useAuth';
import useTables from './TableParts/TableHooks/useTables';
import TableSearchBar from './TableParts/TableSearchBar/TableSearchBar';
import TableHeaders from './TableParts/TableHeaders/TableHeaders';
import TableBody from './TableParts/TableBody/TableBody';
import TableTotals from './TableParts/TableTotals/TableTotals';

const TableListed = ({id, classes, 
                      layout, data, 
                      noURL, masked, 
                      resultsValues, callback,
                      searchBarContent }) => {
    const { getCountry } = useAuth();
    const { tableSearches, tableSorts, 
            tableDropdowns, getTableColumns,
            updateTable, updateTableWithURL } = useTables();
    const [ freeze, setFreeze ] = useState();
    const [ rawData, setRawData ] = useState(); 
    const [ searchStarted, setSearchStarted ] = useState();
    const runOnce = useRef();
    const searchTimer = useRef();
    
    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        layout.countryCode = getCountry();
        if (!noURL) {updateTableWithURL(id, layout)};
        setFreeze([false]);
    }, []);

    useEffect(()=>{
        if (!data) {return}
        data && setRawData(data);
        layout.countryCode = getCountry();
        const newData = runFilters(layout, data);
        updateTable('data', id, newData, noURL);
    }, [data, layout])

    useEffect(() => {
        if (!tableSearches && ! tableSorts && !tableDropdowns) {return}
        setFreeze([true]);
        clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(()=>{ 
            setSearchStarted([true]);
        }, 200)
        return () => {
            if (searchTimer.current) clearTimeout(searchTimer.current);
        };
    }, [tableSearches?.[id], tableSorts?.[id], tableDropdowns?.[id]])

    useEffect(() => {
        setFreeze([false]);
        if (searchStarted?.[0] && layout) {
            setSearchStarted([false]);
            updateTable('data', id, runFilters(layout), noURL);
        }
    }, [searchStarted]);

    const runFilters = (layout, data) => {
        let info = deepCopy(data ? data : rawData ? rawData : []);
        const dropdowns = tableDropdowns?.[id];
        if (dropdowns) { info = filterByDropdowns(info, dropdowns)};
        const input = ifElse(tableSearches?.[id]);
        if (input && info.length > 0 ) {info = filterByInput(info, input)}
        const subtotals = layout?.subtotals;
        const columns = getTableColumns(id, layout);
        const sort = tableSorts?.[id] ? tableSorts[id] : layout?.defaultSort ? layout.defaultSort : null;
        if (sort) {
            const sortProp = sort ? sort.orderBy : 'id';
            const desc = sort ? sort.desc : null;
            info = [...sortList(info, sortProp, desc)];
        }
        const totals = getSubtotalContent(columns, subtotals, info);
        updateTable('totals', id, totals, noURL);
        // NOTE: IF YOU WANT TO KEEP THE GROUPING TOGETHER IF POSSIBLE EVEN ON SORTS, REMOVE THE TABLESORTS PART OF THE BELOW CHECK
        if (subtotals && !tableSorts?.[id]) {
            if (subtotals.groupBy !== 'all' && info.length >= 1) {
                const groupBy = subtotals.groupBy;
                const uniqueCount = getUniqueCount(info, groupBy);
                if (uniqueCount > 1) {
                    info = insertSubtotalRows(columns, info, subtotals, groupBy);
                }
                return info;
            } else {
                return info;
            }
        } else {
            return info;
        }
    }

    const getUniqueCount = (items, propName) => {
        const uniqueValues = new Set();
        items.forEach(item => {
            if (item.hasOwnProperty(propName)) {
                uniqueValues.add(item[propName]);
            }
        });
        return uniqueValues.size;
    }
    
    const filterByDropdowns = (info, dropdowns) => {
        if (!dropdowns || typeof dropdowns !== 'object') {return info};
        const props = Object.keys(dropdowns);
        let newInfo = [];
        props.forEach((prop, i) => {
            const dropdown = dropdowns[prop];
            if (dropdown.value === null) {
                newInfo = info;
            } else { 
                const searchData = dropdown.searchData;
                const dropdownValue = dropdown[searchData.dropdownProp];
                const recordProp = searchData.recordProp;
                const length = info.length;
                for (let i=0; i<length; i++) {
                    const record = info[i];
                    if (record[recordProp].toString() === dropdownValue.toString()) {
                        newInfo.push(info[i]);
                    }
                }
            }
        });
        return newInfo;
    }

    const filterByInput = (info, string) => {
        if (!string || string.length === 0) { return info};
        string = string.trim();
        const keys = Object.keys(info?.[0]).filter(key => key !== 'index');
        const newInfo = [];
        info.forEach((record, i) => {
            const appends = layout.appends;
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                const appendProp = appends?.[key];
                let value;
                if (appendProp) {
                    value = formatContent(record[key], key, layout) + ' (' + formatContent(record[appendProp], appendProp, layout) + ')';
                } else {
                    value = formatContent(record[key], key, layout);
                }

                if (isValid(value) && foundMatch(string, value)) {
                    newInfo.push(info[i])
                    break;
                }
            }
        })
       return newInfo;
    }

    const insertSubtotalRows = (columns, rows, subtotals, groupBy) => {
        let current = rows[0][groupBy];
        let newRows = []
        let matchingSet = [];
        let nullSet = []
        rows.forEach((row) => {
            const val = row[groupBy];
            if (val === current) {
                !current ? nullSet.push(row) : matchingSet.push(row);
            } else {
                if (current && matchingSet.length === 1) {
                    newRows.push(matchingSet[0]);
                } else if (current) {
                    newRows.push({ subtitle: getSubtitleContent(columns, subtotals, current)});
                    newRows.push.apply(newRows, matchingSet);
                    newRows.push({ subtotals: getSubtotalContent(columns, subtotals, matchingSet)});
                }
                matchingSet = [row];
                current = val;
            }
        });

        if (matchingSet.length > 1) {
            newRows.push({ subtitle: getSubtitleContent(columns, subtotals, current)});
            newRows.push.apply(newRows, matchingSet);
            newRows.push({ subtotals: getSubtotalContent(columns, subtotals, matchingSet)});
        } else if (matchingSet.length === 1) {
            newRows.push(matchingSet[0]);
        }  
        if (nullSet.length === 1) {
            newRows.push(nullSet[0]);
        } else if (nullSet.length > 1) {
            newRows.push({ subtitle: getSubtitleContent(columns, subtotals, null)});
            newRows.push.apply(newRows, nullSet);
            newRows.push({ subtotals: getSubtotalContent(columns, subtotals, nullSet)});
        }
        return newRows;
    }

    const foundMatch = (string, value) => {
        if (value.toString().toUpperCase().includes(string.toUpperCase())) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div id={id} className={`table ${classes ? classes : ''} ${(freeze?.[0] || masked)?  'freeze' : ''} `} >
            <TableSearchBar id={id} layout={layout} searchBarContent={searchBarContent} masked={masked} noURL={noURL}/>
            <div id={id + '-container'} className={`table-container`}>
                <TableHeaders id={id} layout={layout} noURL={noURL}/>
                <TableBody id={id} layout={layout} masked={freeze?.[0] || masked} noURL={noURL} callback={callback} />
                <TableTotals id={id} layout={layout} resultsValues={resultsValues} classes={'listed'} loading={freeze?.[0] || masked} noURL={noURL}/>
            </div>
        </div>
    )
}

export default TableListed;
