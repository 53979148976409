import './permissionsUsers.scss';
import { useEffect, useRef, useState } from 'react';
import { deepCopy, isValid } from 'components/shared/componentUtils';
import { permissionTabsList as tabs } from 'layouts/Permissions/PermissionTabs';
import { roles, ranksById } from 'shared/data';
import { text as t } from 'shared/text';
import useAuth from 'hooks/useAuth';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
import UserLocationsModal from 'modals/FlowModals/UserLocationsModal/UserLocationsModal';

const PermissionsUsers = ({classes, subPage, singleUserId, userTabs}) => {
    const { getAuth } = useAuth();
    const { inputValues, dropdownDisplays,
            clearAllInputs, updateDropdown } = useInputs();
    const { addModal, removeModal } = useModal();
    const { setHeader } = useProfile();
    const { callAPI, callAPIGet, getRolesList,
            hasRank, updateHeaderMessage } = useUtils();
    const [ template, setTemplate ] = useState();
    const [ allPermissions, setAllPermissions ] = useState();
    const [ users, setUsers ] = useState();
    const [ userCompany, setUserCompany ] = useState();
    const [ currentUser, setCurrentUser ] = useState();
    const [ currentUserRole, setCurrentUserRole ] = useState();
    const [ locations, setLocations ] = useState();
    const [ currentLocations, setCurrentLocations ] = useState([]);
    const [ newLocations, setNewLocations] = useState([]);
    const [ locationsMatch, setLocationsMatch ] = useState(true);
    const [ canAlter, setCanAlter ] = useState();
    const [ masked, setMasked ] = useState(true);
    const dataLoaded = useRef(false);
    const permitted = hasRank('owner', true);
    const role = useRef(getAuth().role);
    const is5 = getAuth()?.role?.id === 5;
    const roleId = 'permissions-user-roleId';
    const userId = 'permissions-user-userId';

    useEffect(() => {
        clearAllInputs();
        !isValid(users) && callAPI('users', usersCallback, permitted ? {} : {companyId: getAuth().companyId});
        const params = {loggedInUserRoleId: role.current.id}
        callAPI('roleTemplateGet', roleTemplateGetCallback, params);
        !singleUserId && setHeader(t.permissions);
    }, []);

    useEffect(()=>{
        if (template && currentLocations && !dataLoaded.current) {
            dataLoaded.current = true;
            setMasked(false);
        }
    }, [template, currentLocations, currentUserRole])

    useEffect(()=>{
        if (allPermissions && singleUserId) {
            setCurrentUser(singleUserId);
            callAPI('userDetails', userDetailsCallback, {id: singleUserId}, '', {value: singleUserId});
            callAPIGet('userLocationsGet', userLocationsGetCallback, {UserId: parseInt(singleUserId)});
        }
    }, [allPermissions])

    useEffect(()=>{
        if (inputValues[userId] && currentUser && currentUserRole) {
            updateDropdown(roleId, currentUserRole.value, currentUserRole.label);
        }
    }, [currentUser])

    useEffect(() => {
        if (!newLocations) {return}
        const match = checkListMatch();
        setLocationsMatch(match);
    }, [newLocations])

    const usersCallback = (data) => {
        if (data?.isSuccessful) {
            const list = data?.webPortalUserList ? data.webPortalUserList : [];
            setUsers(list);
        }
    }

    const roleTemplateGetCallback = (data) => {
        if (data?.isSuccessful) {
            const all = data.rolesAndPermissions;
            if (all.LocationManager) {
                delete all.LocationManager.Permissions;
                delete all.LocationManager.Users;
            }
            const permissions = all[role.current.name];
            setAllPermissions(all);
            setTemplate(permissions);
        }
    }

    const userDetailsCallback = (data, userData) => {
        const details = data?.webPortalUser;
        const companyId = details.companyId;
        if (!details) {
            setCurrentUser(null);
            return
        };
        const roleId = details?.roleId;
        const userRole = {
            value: roleId,
            label: roles.labels[roleId]
        };
        checkCanAlter(userRole);
        setCurrentUser(userData.value.toString());
        setCurrentUserRole(userRole);
        setUserCompany(companyId);
        const params = {
            companyId: companyId,
            validAddress: true
        }
        callAPI('locationsByCompany', locationsByCompanyCallback, params);
    };

    const userLocationsGetCallback = (data) => {
        const userLocations = data?.locations ?? [];
        setCurrentLocations(userLocations);
        setNewLocations(userLocations);
    }

    const locationsByCompanyCallback = (data) => {
        setLocations(data?.tableData ? data.tableData.sort((a, b) => a.id - b.id) : []);
    }

    const onUserSelect = (data) => {
        const value = data.value;
        callAPI('userDetails', userDetailsCallback, {id: value}, '', data);
        callAPIGet('userLocationsGet', userLocationsGetCallback, {UserId: parseInt(value)});
    }

    const onUpdateRole = (event, setLoading) => {
        setLoading(true);
        const params = {
            webPortalUserId: currentUser,
            roleId: inputValues[roleId],
            companyId: userCompany
        };
        const props = {
            event: event,
            setLoading: setLoading,
        }
        callAPI('roleAssign', rollAssignCallback, params, null, props);
    }

    const rollAssignCallback = (data, props) => {
        props.setLoading(false)
        updateHeaderMessage(data, props);
        if (data?.isSuccessful) {
            const newRole = {
                value: inputValues[roleId],
                label: dropdownDisplays[roleId]
            }
            setCurrentUserRole(newRole);
            checkCanAlter(currentUser, newRole);
        }
    }

    const onLocationSelect = (data) => {
        const val = data.value;
        if (!val) {return}
        const exists = newLocations.some(obj => obj.id === val);
        if (!exists) {
            const newList = deepCopy(newLocations);
            let insertIndex = newList.findIndex(obj => obj.id > val);
            if (insertIndex === -1) {
                insertIndex = newList.length;
            }
            newList.splice(insertIndex, 0, {
                id: val,
                name: data.label
            });
            setNewLocations(newList);
        }
    }

    const onLocationRemove = (locationId) => {
        const newList = deepCopy(newLocations).filter(obj => obj.id !== locationId);
        setNewLocations(newList);
    }   

    const onUpdateLocations = () => {
        addModal(<UserLocationsModal id={currentUser} currentList={currentLocations} newList={newLocations} callback={userLocationsSetCallback}/>)
    } 

    const userLocationsSetCallback = (data, setLoading) => {
        setLoading(false);
        removeModal();
        updateHeaderMessage(data);
        if (data.isSuccessful) {
            setCurrentLocations(newLocations);
        }
    }

    const checkCanAlter = (userRole) => {
        if (singleUserId === getAuth().id) {
            setCanAlter(false)
        } else if (hasRank('admin')) {
            setCanAlter(true);
        } 
        else if (hasRank('owner') && role.current.rank < ranksById[userRole.value]) {
            setCanAlter(true);
        } else {
            setCanAlter(false);
        }
    }

    const checkListMatch = () => {
        const length1 = currentLocations.length;
        const length2 = newLocations.length; 
        if (!length1 && !length2) {return true};
        if (length1 !== length2) {return false};
        for (let i = 0; i < length1; i++) {
            if (currentLocations[i].id !== newLocations[i].id) {
                return false;
            }
        }
        return true;
    }

    const buildLocationsList =  (size) => {
        return (<div className={`grid permissions-locations-list ${size}`}>
            <div className='permissions-locations-label full'>{t.includedLocations}</div>
            <div className='permissions-locations-list-content full'>
                {newLocations.length === 0 ? (<div className='permissions-location-item full grid filler'>
                    <span className='permissions-location-label'>{t.none}</span>
                </div>) :
                (newLocations.map((location, i)=>{
                    return (<div key={i} className='permissions-location-item full'>
                        {!is5 && <Button
                            id={location.id}
                            classes='permissions-location-remove-button quarter blue'
                            icon='minus'
                            onClick={() => {onLocationRemove(location.id)}}
                        />}
                        <span className='permissions-location-label three-quarters'>{location.name}</span>
                    </div>)
                }))}
            </div>
        </div>)
    }

    return (
        <Page
            classes={classes ? classes : ''}
            subPage={(subPage || subPage === null) ? subPage : t.users}
            tabs={userTabs ? userTabs : tabs}
            contentClasses='grid'
        >
            <span className='half'>
                {!singleUserId && <Card hideLabel={true}>
                    <Dropdown id={userId} classes='full' masked={masked}
                        label={t.users} data={users} callback={onUserSelect}/>
                </Card>}
                {currentUser === null && <Card hideLabel={true}>
                    <div className='permissions-no-user full'>{t.noUserData}</div>
                </Card>}
                {currentUser && <Card hideLabel={true}>
                    <div className='permissions-card-label full'>{t.role}</div>
                    <div className={`permissions-users-roles-line-container full ${currentUserRole? 'show' : ''}`}>
                        {!currentUserRole ? <div className='permissions-no-results'>{t.noRoleAssigned}</div> :
                        <div className={`permissions-users-role-line`}>
                            {currentUserRole?.label}
                        </div>}
                    </div>
                    {(permitted && canAlter) && <Dropdown id={roleId} classes='full' masked={masked} labelProp='role'
                        label={t.updateRole} data={getRolesList()}/>}
                    {(currentUser && permitted && canAlter) && <span className='full grid'>
                        <Button
                            classes='third blue last'
                            type='submit'
                            onClick={(event, setLoading)=>{onUpdateRole(event, setLoading)}}
                            disabled={!inputValues?.[roleId] || inputValues?.[roleId] === currentUserRole?.value}
                        >{t.updateRole}</Button>
                    </span>}
                </Card>}
            </span>
            {is5 && buildLocationsList('half')}
            {(currentUserRole?.value === 5 && !is5) && <Card hideLabel={true} classes='half'>
                {is5 ? <div className='half'/> : <Dropdown id='location-selector' classes='full' masked={masked}
                    label={t.locations} data={locations} callback={onLocationSelect}/>}
                {buildLocationsList('full')}
                {<Button
                    classes='third blue last'
                    onClick={onUpdateLocations}
                    disabled={locationsMatch}
                >{t.updateLocations}</Button>}
            </Card>}
        </Page>
    )
}

export default PermissionsUsers;
