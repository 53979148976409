import './checkbox.scss';
import { useEffect } from 'react';
import { getFormId } from '../shared/componentUtils';
import useInputs from '../hooks/Inputs/useInputs';
import Icon from '../Icon/Icon';

const Checkbox = ({ id, section, 
                    line, index,
                    classes, label, 
                    disabled, displayOnly,
                    radioData, value, 
                    noPermission, showFail,
                    hasError, callback}) => {
    const { inputValues, inputErrors, 
            updateInput, clearError } = useInputs();
    id = line ? getFormId(section, line, index) : id;
    useEffect(()=>{
        if (radioData) {
            if (radioData !== id && radioData?.id !== id) {
                updateInput(id, false);
            } else {
                updateInput(id, true);
            }
        }
    }, [radioData]);

    const onCheck = () =>{
        clearError(id);
        if (disabled) {return}
        const newVal = !inputValues[id]
        updateInput(id, newVal);
        if (callback) {
            callback({
                id: id,
                value: (radioData && newVal) ? value : newVal,
                section: section,
                line: line,
                index: index,
            })
        };
    }

    return (
        <div id={id} className= {`checkbox-container ${classes ? classes : ''} ${!label ? 'hide-label' : ''} ${noPermission || displayOnly ? 'display-only' : ''}`}>
            {label ? <div className='checkbox-label'>{label}</div> : null}
            <div className='checkmark-container'>
                <span
                    className={`checkmark ${radioData ? 'radio' : ''} ${(inputValues[id] || value) && !showFail ? 'checked' : ''}`}
                    tabIndex='-1'
                    onClick={()=>{onCheck()}}
                >
                    {!showFail ? <Icon icon='check' classes='checkmark-check'/> : <Icon icon='x-large' classes='checkmark-x'/>}
                </span>
            </div>
            {hasError && <div className='checkbox-error-message'>{inputErrors?.[id]}</div>}
        </div>
    )
}

export default Checkbox;
