import './deviceControls.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deepCopy } from 'components/shared/componentUtils';
import { deviceControlsLayout as layout } from 'layouts/Devices/DeviceDetails/DeviceControlsLayout';
import { deviceTabsList } from 'layouts/Devices/DeviceDetails/DeviceTabs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Card from 'app-components/Cards/Card/Card';
import ContextDropdown from 'app-components/ContextMenu/ContextDropdown/ContextDropdown';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const controlsLayout = {
    id: 'device-controller-menu',
    contextMenu: [
        'reboot',
        'pauseService',
        'startService',
        'sendLogs',
        'setLocation',
        'executeScript',
        'openSupportTunnel'
    ]
};

const DeviceControls = () => {
    const { callAPI, callAPIGet } = useUtils();
    const { id } = useParams();
    const { setHeader, updateDeviceBreadcrumbs } = useProfile();
    const [ pageData, setPageData ] = useState();
    const [ tableData, setTableData ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const [ menuLayout, setMenuLayout ] = useState(controlsLayout);
    const runOnce = useRef(false);
    const headers = layout.headers;

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = {terminalId: id};
        callAPIGet('deviceDetails', deviceDetailsCallback, params);
        callAPI('deviceLogs', deviceLogsCallback, params);
    }, []);

    const deviceDetailsCallback = (data) => {
        if (!data.isSuccessful) { 
            setMasked([false]);
            return;
        }
        setPageData(data);
        const type = data.terminalType;
        setHeader(type.name + ': ' + data.terminalId);
        updateDeviceBreadcrumbs(data);
        if (type.id === 4) { // POS 
            const newLayout = deepCopy(controlsLayout);
            newLayout.contextMenu.push('resetPOSAdminPassword');
            setMenuLayout(newLayout); 
        }
    }

    const deviceLogsCallback = ( data ) => {
        setTableData(data?.tableData ? data.tableData : []);
        setMasked([false]);
    } 
    
    return (
        <Page
            subPage={layout.subPage} 
            tabs={deviceTabsList} 
            classes={'device-details-controls'}
        >
            <Card classes='full device-commands-card' label={headers.deviceCommands}>
                <ContextDropdown menu={false} classes='third' layout={menuLayout}/>
            </Card>
            <div className='device-controls-table-label'>{headers.deviceLogs}</div>
            <Table
                data={tableData}
                layout={layout}
                context={useProfile}
                loading={masked}
            />
        </Page>

    )
}

export default DeviceControls;
