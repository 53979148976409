import axios from 'axios';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash/string';
import { devicesTabsList } from 'layouts/Devices/DevicesTable/DevicesTabs';
import { baseUrl } from 'shared/utils';
import { ranks, viewRanks, roles } from 'shared/data';
import { text as t } from 'shared/text';
import useAuth from './useAuth';
import useProfile from './useProfile';

const useUtils = () => {
    const { id } = useParams();
    const { getAuth, callRefresh, callLogout } = useAuth();
    const { username, originalCalls, 
            devicesTabs, setDevicesTabs,
            setHeaderMessage } = useProfile();
    const refreshCount = useRef([0]);

    const callAPI = async ( id, callback, params, newAccessToken, props ) => {
        const URL = baseUrl() + getURL(id);
        const reqConfig = getBaseHeaders(newAccessToken);
        if (props?.reqConfig) {reqConfig[props.reqConfig.prop] = props.reqConfig.value};
        if (props?.headers) {reqConfig.headers[props.headers.prop] = props.headers.value};
        if (!params) {params = {}}
        try {
            const response = await axios.post(URL, params, reqConfig);
            handleCallResponse(response, callback, props, newAccessToken);
        } catch (err) {
            if (err.response?.status === 401) {
                if (refreshCount.current[0] >= 3) {
                    refreshCount.current[0] = 0;
                    throw new Error(err);
                } else {
                    refreshCount.current[0] = refreshCount.current[0] + 1;
                    const originalCall = {
                        id: id,
                        callback: callback,
                        params: params,
                        callAPI: callAPI,
                    }
                    if (refreshCount.current[0] === 1) {
                        await callRefresh(refreshCount.current[0], originalCall, props, resetCount);
                    } else {
                        originalCalls.current = [...originalCalls.current, originalCall]
                    }
                }
            } else {
                handleCallResponse(err.response, callback, props);
            }
        }
    }

    const callAPIGet = async ( id, callback, params, newAccessToken, props ) => {
        const URL = baseUrl() + getURL(id);
        const reqConfig = getBaseHeaders(newAccessToken);
        if (!params) { params = {}}
        try {
            const response = await axios.get(URL, {...reqConfig, params: params});
            handleCallResponse(response, callback, props, newAccessToken);
        } catch (err) {
            if (err.response?.status === 401) {
                if (refreshCount.current[0] >= 3) {
                    refreshCount.current[0] = 0;
                    throw new Error(err);
                } else {
                    refreshCount.current[0] = refreshCount.current[0] + 1;
                    const originalCall = {
                        id: id,
                        callback: callback,
                        params: params,
                        callAPIGet: callAPIGet,
                    }
                    if (refreshCount.current[0] === 1) {
                        await callRefresh(refreshCount.current[0], originalCall, props, resetCount);
                    } else {
                        originalCalls.current = [...originalCalls.current, originalCall]
                    }
                }
            } else {
                handleCallResponse(err.response, callback, props);
            }
        }
    }

    const callAPIGetBlob = async ( id, callback, params, newAccessToken, props ) => {
        const token = getAuth();
        const URL = baseUrl() + (props && props.id ? getURL(id) + '/' + props.id : getURL(id));
        const reqConfig = {
            responseType: 'blob', // important
            headers: {
                'Access-Token': `${newAccessToken ? newAccessToken : token.accessToken}`
            }
        };
        if (!params) {
            params = {}
        }
        try {
            const response = await axios.get(URL, {...reqConfig, params: params});
            handleCallResponse(response, callback, props, newAccessToken);
        } catch (err) {
            if (err.response?.status === 401) {
                if (refreshCount.current[0] >= 3) {
                    refreshCount.current[0] = 0;
                    throw new Error(err);
                } else {
                    refreshCount.current[0] = refreshCount.current[0] + 1;
                    const originalCall = {
                        id: id,
                        callback: callback,
                        params: params,
                        callAPIGetBlob: callAPIGetBlob,
                    }
                    if (refreshCount.current[0] === 1) {
                        await callRefresh(refreshCount.current[0], originalCall, props, resetCount);
                    } else {
                        originalCalls.current = [...originalCalls.current, originalCall]
                    }
                }
            } else {
                handleCallResponse(err.response, callback, props);
            }
        }
    }

    const callAPIPatch = async ( id, callback, params, newAccessToken, props ) => {
        const URL = baseUrl() + getURL(id);
        const reqConfig = getBaseHeaders(newAccessToken);
        if (props?.reqConfig) {
            reqConfig[props.reqConfig.prop] = props.reqConfig.value
        }
        if (props?.headers) {
            reqConfig.headers[props.headers.prop] = props.headers.value;
        }
        if (!params) {
            params = {}
        }
        try {
            const response = await axios.patch(URL, params, reqConfig);
            handleCallResponse(response, callback, props, newAccessToken);
        } catch (err) {
            if (err.response?.status === 401) {
                if (refreshCount.current[0] >= 3) {
                    refreshCount.current[0] = 0;
                    throw new Error(err);
                } else {
                    refreshCount.current[0] = refreshCount.current[0] + 1;
                    const originalCall = {
                        id: id,
                        callback: callback,
                        params: params,
                        callAPIPatch: callAPIPatch,
                    }
                    if (refreshCount.current[0] === 1) {
                        await callRefresh(refreshCount.current[0], originalCall, props, resetCount);
                    } else {
                        originalCalls.current = [...originalCalls.current, originalCall]
                    }
                }
            } else {
                handleCallResponse(err.response, callback, props);
            }
        }
    }

    const resetCount = () => {
        refreshCount.current[0] = 0;
    }

    const handleCallResponse = ( response, callback, props, accessToken ) => {
        const data = response?.data;
        if (data?.headers && props?.type !== 'blob' ) {
            data.headers = response?.headers;
        }
        callback(data, props, accessToken);
    };

    const getBaseHeaders = (newAccessToken) =>{
        const token = getAuth();
        return {
            headers: {
                'Caller-Id': `${username ? username : token.username ? token.username : ''}`,
                'Access-Token': `${newAccessToken ? newAccessToken : token.accessToken}`
            }
        }
    }

    const getURL = ( id ) => {
        switch (id) {
            case 'accountSearchByPhone':
                return '/v2/account/search-by-phone';
            case 'accountDelete':
                return '/account/delete';
            case 'accountsDelete':
                return '/v2/account/delete-player-accounts'
            case 'adminPinChange':
                return '/v2/web-portal-users/generate-pin';
            case 'apiLogs':
                return '/v2/log/api-gateway';
            case 'loggerDetails':
                return '/log/gateway-logger-detail/get-view-table-data';
            case 'assignedUsers':
                return '/v2/Role/get-users-assigned-to-role';
            case 'commissionTerminal':
                return '/v2/terminal/commission-terminal';
            case 'companies':
                return '/companies/get-view-table-data';
            case 'companiesByUser':
                return '/companies/company-name/get-view-table-data';
            case 'companyAlertsGet':
                return '/v2/company/get-alerts';
            case 'companyAlertsSet':
                return '/v2/company/set-alerts';
            case 'companyDetails':
                return '/companies/get-view-table-detail-data';
            case 'companyNameUnique':
                return '/v2/company/check-name-uniqueness';
            case 'companyUpdate':
                return '/companies/update-view-table-detail-data';
            case 'companyUsers':
                return '/companies/associated-users/get-view-table-data';
            case 'companyLogo':
                return '/companies/get-company-logo';
            case 'companyLogoUpload':
                return '/companies/add-company-logo';
            case 'companyServicesGet':
                return '/v2/company/get-service-rates';
            case 'companyServicesSet':
                return '/v2/company/set-service-rates';
            case 'countriesGet':
                return '/v2/lookup-data/get-countries';
            case 'downloadLogFile':
                return '/log/retrieve-log-file';
            case 'environments':
                return '/updates/environment-name-type/get-view-table-data';
            case 'devices':
                return '/terminal-list/get-view-table-data';
            case 'deviceDetails':
                return '/v2/terminal/get-details';
            case 'deviceLocationUpdate':
                return '/terminal-status/update-terminal-location';
            case 'deviceCompanyUpdate':
                return '/terminal-status/update-terminal-company';
            case 'deviceLogs':
                return '/log/file/get-view-table-data';
            case 'deviceTypes':
                return '/terminal/get-kiosk-types';
            case 'emailUniqueCheck':
                return '/v2/web-portal-users/unique-email-check'
            case 'gameCashBreakdowns':
                return '/v2/terminal-info/details/get-cash-breakdown';
            case 'locations':
                return '/portal/location/get-view-table-data';
            case 'locationsGetByCompanyId':
                return '/v2/location/get-locations';
            case 'locationAlertsGet':
                return '/v2/location/get-location-alerts';
            case 'locationAlertsSet':
                return '/v2/location/set-location-alerts'
            case 'locationAlertsContacts':
                return '/v2/location/get-location-alert-contacts'
            case 'locationCreate':
                return '/v2/location/create-location';
            case 'locationDetails':
                return '/portal/location/get-view-table-detail-data';
            case 'locationNew':
                return '/v2/location/get-location-form-fields-json';
            case 'locationsByCompany':
                return '/portal/location/location-name/get-view-table-data';
            case 'locationServicesGet':
                return '/v2/location/get-location-services';
            case 'locationServicesSet':
                return '/v2/location/set-location-services';
            case 'locationStatuses':
                return '/portal/location/status-types/get-view-table-data';
            case 'locationTypes':
                return '/portal/location/types/get-view-table-data';
            case 'locationUpdate':
                return '/v2/location/update-location';
            case 'locationDevices':
                return '/terminal-info/details/get-view-table-data-by-location';
            case 'locationConfiguration':
                return '/StormDataCollection/get-settings';
            case 'locationConfigurationUpdate':
                return '/StormDataCollection/set-settings';
            case 'manifests':
            case 'manifestDetails':
                return '/updates/manifest/get-view-table-data';
            case 'manifestDelete':
                return '/v2/update/manifest/delete';
            case 'manifestUpdate':
                return '/updates/manifest/update-view-table-data';
            case 'report-CompanyPerformance':
                return '/report/customer';
            case 'report-GamePerformance':
                return '/v2/report/game-performance-totals';
            case 'report-GameroomPerformance':
                return '/v2/report/game-room-location-performance';
            case 'report-KioskPerformance':
                return '/v2/report/grckiosk/performance';
            case 'report-LocationAudit':
                return '/v2/report/get-audit-transactions';
            case 'report-LocationPerformance':
                return '/v2/report/location/performance';
            case 'report-DeviceGames':
                return '/v2/report/game-performance-details';
            case 'report-DeviceTransactions':
                return '/v2/terminal/get-transactions';
            case 'report-Jackpot':
                return '/v2/report/jackpots';
            case 'report-LocationGame':
                return '/v2/report/location-game-performance-report';
            case 'report-LocationGameroom':
                return '/v2/report/location/get-game-room-report';
            case 'report-LocationPlayerInfo':
                return '/v2/report/location/player-trends';
            case 'report-LocationTransactions':
                return '/v2/Location/get-transactions';
            case 'roleAssign':
                return '/v2/web-portal-users/assign-role';
            case 'roleTemplateGet':
                return '/v2/Role/get-roles-and-permissions-for-user-level';
            case 'rolesListGet':
                return '/v2/Role/get-list';
            case 'sources':
            case 'sourceDetails':
                return '/updates/source/get-view-table-data';
            case 'sourceTypes':
                return '/updates/source-type/get-view-table-data';
            case 'sourceUpdate':
                return '/updates/source/update-view-table-data';
            case 'statesGet':
                return '/v2/lookup-data/get-states';
            case 'terminalPost':
                return '/terminal-command/post';
            case 'deviceSubtypes':
                return '/terminal-subclass/get-view-table-data';
            case 'deviceSubtypeAdd':
                return '/terminal-subclass/add';
            case 'deviceInfoUpdate':
                return '/terminal-info/details/update';
            case 'users':
                return '/web-portal-users/get-list';
            case 'userDetails':
                return '/web-portal-users/get';
            case 'userLocationsGet':
                return '/v2/location/user/get-locations';
            case 'userLocationsSet':
                return '/v2/web-portal-users/assign-locations';
            case 'userCreate':
                return '/v2/web-portal-users/create';
            case 'userUpdate':
                return '/v2/web-portal-users/update';
            case 'userPasswordSet':
                return '/portal/web-portal-user/change-password'; //use for both create and update
            default:
                return '';
        }
    }

    const hasRank = (type, omitPersonal) =>{
        if (type === 'all') return (true);
        if (!type) {return false}
        if (!omitPersonal && hasPersonalPermission(window.location.pathname)) {return true}
        const rank = getAuth()?.role?.rank;
        return rank <= ranks[type] ? true : false;
    }

    const canView = (path) => {
        if (!path) {return false}
        if (hasPersonalPermission(path)) {return true}
        const rank = getAuth()?.role?.rank;
        return rank <= viewRanks[path] ? true : false;
    }

    const hasPersonalPermission = (path) => {
        if (path.includes('/company/')) {
            return isYourCompany();
        } else if (path.includes('/user/')) {
            return isYourProfile();
        } else {
            return false
        }
    }

    const isYourCompany = () => {
        const companyId = getAuth().companyId;
        if (!companyId) {
            callLogout()
            setHeaderMessage(t.credentialsExpired);
        } else {
            return id && id.toString() === companyId.toString() ? true : false;
        }
    }

    const isYourProfile = () => {
        const userId = getAuth().id;
        if (!userId) {
            callLogout()
            setHeaderMessage(t.credentialsExpired);
        } else {
            return id && id.toString() === userId.toString() ? true : false;
        }
    }

    const updateDevicesTabs = () => {
        const root = window.location.origin;
        const token = getAuth();
        const services = token.serviceTypes;
        const all = hasRank('owner');
        if (!devicesTabs.length && services) {
            const newTabs = [];
            newTabs.push(devicesTabsList.all);
            Object.keys(services).map((type) => {
                (all || services[type]) && newTabs.push(devicesTabsList[type])
            })
            const env = (root.includes('localhost') || root.includes('dev-pwp')) ? 'dev': 'alt';
            env === 'dev' && newTabs.push(devicesTabsList.monitor);
            setDevicesTabs(newTabs)
        }
    }

    const updateHeaderMessage = (data, props) => {
        if (props?.target) {
            placeHeader(props.target);
        } else if (props) {
            const targetClass = props?.targetClass ? props.targetClass: '.standard-button';
            placeHeaderAtButton(props.event.target, targetClass);
        } else {
            replaceHeaderAtButton();
        }
        if (data === 'formErrors') {
            setHeaderMessage(t.formErrors);
            return;
        }
        setTimeout(() => {
            props?.setLoading && props.setLoading(false);
            if (data?.isSuccessful) {
                setHeaderMessage(t.updateComplete);
                return;
            }
            if (data?.errorMessage && data.errorMessage !== '') {
                setHeaderMessage(capitalize(data.errorMessage));
                return;
            }
            setHeaderMessage(t.updateFailed);
        }, 500);
    }

    const getRolesList = () => {
        const rolesList = [];
        const rank = getAuth()?.role?.rank;
        roles.list.forEach((role)=>{
            rank <= role.rank && rolesList.push(role);
        })
        return rolesList;
    }

    const placeHeader = (target) => {
        const message = document.getElementById('update-notice');
        const position = target.getBoundingClientRect();
        message.style.left = (position.left + (target.clientWidth / 2)) + 'px';
        message.style.top = (position.top + -message.clientHeight - 15) + 'px';
    }

    const placeHeaderAtButton = (target, targetClass) => {
        const message = document.getElementById('update-notice');
        const button = target.closest(targetClass) ? target.closest(targetClass) : target.closest('.button');
        const position = button.getBoundingClientRect();
        message.style.left = (position.left + (button.clientWidth / 2)) + 'px';
        message.style.top = (position.top + -message.clientHeight - 15) + 'px';
    }

    const replaceHeaderAtButton = () => {
        const message = document.getElementById('update-notice');
        message.style.left = '50%';
        message.style.top = '40px'; // arbitrary
    }

    return {
        callAPI, callAPIGet,
        callAPIGetBlob, callAPIPatch,
        canView, hasRank, getRolesList,
        getURL, getBaseHeaders,
        isYourCompany, handleCallResponse, 
        updateDevicesTabs, updateHeaderMessage
    }
}

export default useUtils;
